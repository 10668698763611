<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper
          :items="challengeTypes"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Tipos de retos
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/ChallengesTypesTable.vue";
import {getChallengesDataTypes, checkUser} from '../../utils/utils';
export default {
  name: "ChallengesTypeTable",
  components: {
    CTableWrapper
  },
  data: () => ({
    challengeTypes: [],
  }),
  created() {
    if(!checkUser()) return;
    this.receiveData();
  },
    methods: {
    receiveData() {
      this.getChallengesTypes();
    },
    /**
     * Función en la que se obtienen los tipos retos y que también
     * llama el metodo receiveData para cargarlos.
     * 
     * @returns {object[]} Los tipos de retos obtenidos.
     */
    getChallengesTypes() { 
      let challengesTypesData = getChallengesDataTypes();
      challengesTypesData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let data = [];
        result.challenge_types.forEach(challengeType => {
          let element = {};
          element.challenge_type_id = challengeType.challenge_type_id;
          element.challenge_type_name = challengeType.challenge_type_name;
          data.push(element);
        });
        this.challengeTypes=data;
      });
    },
  },
};
</script>

<style>
</style>