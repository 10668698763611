<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :fields="fields"
        :items="items"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Tipos de retos por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #id="{ item }">
          <td>
            {{ item.challenge_type_id}}
          </td>
        </template>

        <template #challenge_title="{ item }">
          <td>
            {{ item.challenge_name }}
          </td>
        </template>

      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import "core-js/stable";
import "regenerator-runtime/runtime";

export default {
  name: "TablaRetos",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "challenge_type_name",
            label: "Nombre del tipo de reto"
          },
        ];
      },
    },
    caption: {
      type: String,
      default: "Table",
    }
  },
}
</script>
